.form {
  width: 100%;
  max-width: tovw(800);
  margin: 0 auto;
  .error {
    color: $red;
    text-align: left;
    width: 100%;
    display: block;
  }
}
button {
  border: 0;
  outline: 0 !important;
}
.form-line {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="date"],
  input[type="datalist"],
  input[type="time"],
  textarea,
  .selection {
    height: 5rem;
    outline: none !important;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    background-color: #5e0f13;
    transition: border-color 0.25s ease;
    font-size: 2rem;
    text-align: center;
    text-transform: none;
    color: #fff;
    border: 0.3rem solid #3c0508;
    @include screen(800px, 860px) {
      height: 4rem;
      font-size: 1.6rem;
    }
    &:focus,
    &:active {
      border-color: #240203;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $gray;
      font-style: italic;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $gray;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $gray;
      font-style: italic;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $gray;
      font-style: italic;
    }
  }
}
