.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(30);
  text-transform: uppercase;
  color: #5d47fa !important;
  height: tovw(71);
  line-height: tovw(71);
  padding: 0 tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(296);
  position: relative;
  font-weight: 600;
  transition: transform 0.25s ease, color 0.25s ease, background 0.25s ease;
  font-style: italic;
  font-family: $condensed;
  &.active,
  &:hover {
    transform: translateY(-0.5rem);
    background: url(/images/btn-blue.png) no-repeat !important;
    background-size: 100% 100% !important;
    color: #fff !important;
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  @extend %grayscale;
}
