html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  background-color: #000;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color 0.25s ease;
  outline: none !important;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  &--content {
    max-width: tovw(1280) !important;
  }
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
  #root {
    //filter: blur(5px);
  }
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.6) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
    height: auto !important;
    width: tovw(568) !important;
    min-height: tovw(300) !important;
    background: url(/images/popup.jpg) !important;
    background-size: 100% 100% !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 0 !important;
    box-shadow: none;
    .swal2-close,
    .close {
      position: absolute;
      width: 14px;
      height: 14px;
      // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: 10px;
      right: 10px;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #000 !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #f3e1b9;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: tovw(20);
        color: #000;
        line-height: 150%;
        text-transform: uppercase;
      }
    }
    #swal2-title,
    h2 {
      font-size: tovw(40);
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #000;
      justify-content: center;
      font-family: $font-heading;
      font-weight: 600;
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 0.5vw !important;
      overflow: hidden;
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
  }
}
.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 1060 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: tovw(568) !important;
  min-height: tovw(300) !important;
  background: url(/images/popup.jpg);
  background-size: 100% 100%;
  border: 0 !important;
  padding: 2rem 0 !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-history {
    width: tovw(1177) !important;
    min-height: tovw(756) !important;
    padding: tovw(40) tovw(70) !important;

    .modal-description {
      width: 94%;
    }

    .table-history {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      margin-top: tovw(20);

      th {
        font-size: tovw(26);
        height: tovw(50);
        font-family: $font-heading;
        font-weight: normal;
        text-transform: uppercase;
        border-bottom: 1px solid #989898;
        color: #000;

        &:nth-child(2) {
          width: 44%;
        }
      }

      td {
        padding: tovw(7) tovw(5);
        font-size: tovw(20);
        height: tovw(55);
        color: #000;
        font-family: $font-primary;
        border-bottom: 1px solid #989898;
      }
    }
  }
  &.modal-info {
    width: tovw(1000) !important;
    min-height: tovw(600) !important;
    padding: tovw(40) tovw(70) !important;
    .modal-description {
      width: 94%;
    }
    label {
      font-size: tovw(20);
      color: #000;
      text-transform: uppercase;
      font-family: $font-primary;
      font-weight: bold;
      line-height: tovw(60);
    }
    input {
      width: 100%;
      height: tovw(64);
      line-height: tovw(64);
      padding: 0 tovw(15);
      border: tovw(2) solid #000;
      text-transform: uppercase;
      font-size: tovw(20);
      background-color: transparent;
      outline: 2px solid #fff;
    }
  }
  &.modal-register {
    width: tovw(1258) !important;
    min-height: tovw(789) !important;
    background: url("/images/bg-register.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .modal-description {
      width: 80%;
    }
  }
  .close {
    position: absolute;
    width: 14px;
    height: 14px;
    // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: 10px;
    right: 10px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #000 !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    font-size: tovw(40);
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #000;
    justify-content: center;
    font-family: $font-heading;
    font-weight: 600;
  }
  p,
  li {
    font-size: tovw(20);
    color: #000;
  }
}
#main-body {
  position: relative;
  background: url(/images/bg.jpg) no-repeat;
  background-size: 100% 100%;
  min-height: tovw(890);
}
.main-content {
  position: relative;
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #5e47fa;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: 0.5vw !important;
  background: #5e47fa !important;
}
#header {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(500);
    margin-bottom: tovw(18);
  }

  p {
    font-size: tovw(17);
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
  }
}
.logo {
  display: block;
  margin: 0 auto tovw(65) auto;
  width: tovw(175);
}
.main-left {
  position: absolute;
  top: tovw(115);
  left: tovw(260);
  width: tovw(296);
  text-align: center;
}
.menu {
  a {
    display: block;
    margin-bottom: tovw(22);
  }
}
.main-right {
  position: absolute;
  right: tovw(52);
  top: tovw(23);
  width: tovw(415);
  height: 100%;
}
.main-code {
  text-align: center;
  padding-top: tovw(250);
  padding-left: tovw(177);
  &__input {
    input {
      width: 100%;
      height: tovw(74);
      line-height: tovw(74);
      text-align: center;
      padding: 0 tovw(15);
      outline: none;
      border: tovw(2) solid #1a2cb8;
      text-transform: uppercase;
      font-size: tovw(24);
      margin-bottom: tovw(50);
      background-color: transparent;
      outline: 2px solid #fff;
    }
  }
}
.user {
  img {
    width: tovw(305);
  }
  p {
    color: #000000;
    font-style: italic;
    margin-bottom: tovw(45);
    margin-top: tovw(30);
  }
  &__info {
    a,
    strong {
      color: #213368;
      font-weight: bold;
      font-style: normal;
    }
  }
}
.date {
  text-transform: uppercase;
  color: #000;
  font-size: tovw(22);
  font-weight: bold;
  text-align: center;
}
.main-spin {
  padding-top: tovw(60);
  padding-left: tovw(160);
  p {
    color: #000;
    font-size: tovw(18);
    text-align: center;
    margin-bottom: tovw(50);
  }
}
.spin {
  position: relative;
  width: tovw(676);
  margin-left: auto;
  margin-right: auto;
  &::before {
    content: "";
    height: tovw(24);
    width: 100%;
    position: absolute;
    bottom: tovw(1);
    left: 50%;
    transform: translateX(-50%);
    background: url(/images/spin-shadow.png) no-repeat;
    background-size: 100% 100%;
    display: none;
  }
  &__dock {
    display: block;
    width: 100%;
    position: relative;
  }
  &__wheel {
    position: absolute;
    width: 24.11458vw;
    height: 24.11458vw;
    top: 5.7%;
    left: 15.8%;
    img {
      width: 100%;
      display: block;
    }
  }
  &__btn {
    width: tovw(131);
    position: absolute;
    left: 50%;
    margin-left: tovw(-131/2);
    top: 29%;
    z-index: 1;
    height: 23%;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  &__arrow {
    position: absolute;
    top: 1%;
    left: 50%;
    width: tovw(39);
    margin-left: tovw(-39/2);
    img {
      width: 100%;
    }
  }
  &__balance {
    font-family: $fifa;
    font-weight: 300;
    font-size: tovw(28);
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    bottom: 9%;
    left: 0%;
    text-align: center;
    width: 100%;
  }
  &__time {
    font-weight: normal;
    font-size: tovw(16);
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    bottom: 6%;
    left: 0%;
    text-align: center;
    width: 100%;
  }
}
.item-spin {
  width: tovw(60);
  position: absolute;
  top: 5%;
  left: 50%;
  margin-left: tovw(-30);
  height: 45%;
  transform-origin: bottom;
  img {
    width: 100%;
  }
}
.error-mes {
  color: #cf2806;
}
.top {
  position: relative;
  z-index: 11;
  &__bxh {
    position: absolute;
    top: 50%;
    left: tovw(0);
    height: 100%;
    transform: translateY(-50%);
    z-index: 10;
    width: tovw(454);
    height: 100%;
    a {
      position: absolute;
      top: 50%;
      right: tovw(10);
      transform: translateY(-50%) translateX(0);
      display: block;
      width: tovw(58);
      z-index: 2;
      transition: transform 0.34s linear;
      &.open {
        transform: translateY(-50%) translateX(tovw(-439));
      }
    }
    img {
      width: tovw(58);
    }
    &--table {
      position: absolute;
      top: 50%;
      right: tovw(0);
      transform: translateY(-50%) translateX(112%);
      background: url("/images/bg-top.png") no-repeat;
      background-size: 100% 100%;
      padding: tovw(30) tovw(45);
      width: tovw(444);
      transition: transform 0.4s linear;
      border: tovw(4) solid #4e41f4;
      overflow: hidden;
      margin-top: tovw(95);
      h2 {
        font-size: tovw(36);
        color: #4f42f5;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-family: $condensed;
      }
      .row {
        color: #4f42f5;
        font-size: tovw(18);
        text-align: center;
        padding: tovw(15) 0;
        border-top: 1px solid #b7b2f9;
        &.row-first {
          text-transform: uppercase;
          font-weight: bold;
          border-top: 0;
        }
        img {
          width: auto;
          height: tovw(60);
        }
      }
      &.open {
        transform: translateY(-50%) translateX(-8%);
      }
    }
    .list-row {
      height: tovw(680);
    }
  }
}
.wrapper-form {
  p {
    text-align: center;
    font-size: tovw(20);
    margin-bottom: tovw(30);
    &.text-italic {
      font-style: italic;
      font-weight: 300;
    }
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: tovw(10);
  }
  .label {
    font-size: tovw(20);
    text-transform: uppercase;
    line-height: tovw(50);
    color: #000;
    font-weight: bold;
  }
  .form-action {
    width: tovw(515);
    p {
      margin-bottom: 0;
    }
    input {
      width: 100%;
      height: tovw(50);
      line-height: tovw(50);
      padding: 0 tovw(35);
      background-color: #000;
      border: 0;
      outline: none;
      font-size: tovw(20);
      color: #fff;
      &:focus,
      &:active {
        background-color: #471a0a;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
        font-style: italic;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
        font-style: italic;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
        font-style: italic;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
        font-style: italic;
      }
    }
  }
}
.selection {
  width: tovw(368);
  height: tovw(50);
  line-height: tovw(50);
  position: relative;
  color: #000;
  font-size: tovw(20);
  text-transform: uppercase;
  padding-left: tovw(35);
  padding-right: tovw(23);
  background-color: #07f468;
  margin-bottom: tovw(25);
  margin-top: tovw(20);
  img {
    width: tovw(24);
    position: absolute;
    top: 50%;
    right: tovw(23);
    transform: translateY(-50%);
    z-index: 2;
  }
  &:hover {
    .selection__list {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3 ease, visibility 0.3 ease;
    }
  }
  &__list {
    width: tovw(368);
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3 ease, visibility 0.3 ease;
    a {
      display: block;
      margin: 0 auto;
      padding: 0 tovw(5);
      color: #000;
      border-bottom: 1px solid #000;
      padding-left: tovw(35);
      transition: background 0.3s ease, color 0.3s ease;

      &:hover {
        color: #fff;
        background-color: #000;
      }
    }
  }
}
