@keyframes flashing {
  0%, 100% {
    background-color: #a1d435;
  }
  50% {
    background-color: #fff600;
  }
}
@keyframes pointing {
  0%, 20%, 30%, 100%  {
    transform: rotate(0deg);
  }
  15%, 25% {
    transform: rotate(3deg);
  }
}
@keyframes appearing {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(.95);
  }
}
@keyframes floating {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}
@keyframes glowing {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}
@keyframes heartBeat {
  0%, 40%, 80%, 100% {
    transform: scale(1);
  }
  20%, 60% {
    transform: scale(1.1);
  }
}
@keyframes heartBeatLow {
  0%, 40%, 80%, 100% {
    transform: scale(1);
  }
  20%, 60% {
    transform: scale(1.05);
  }
}
@keyframes ping {
  0%, 20%, 40%, 100% {
    transform: translateY(0);
  }
  10%, 30% {
    transform: translateY(-1rem);
  }
}
@keyframes scaling {
  0%, 100% {
    transform: scale(1) rotate(-8deg);
  }
  50% {
    transform: scale(1.1) rotate(-8deg);
  }
}
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}
@keyframes won {
  0%, 100% {
    transform: scale(1);
  }
  30%, 70% {
    transform: scale(1.2);
  }
}